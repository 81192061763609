import React, { useEffect } from "react";
import { RenderModules } from "src/utils/renderModules";
import SEO from "src/components/SEO";
import { Container } from "./styles";
import BlogHero from "src/components/BlogHero";
import { useCart, useDiscount } from "src/context/siteContext";
import applyDiscount from "src/utils/applyDiscount";
import RedirectModal from "src/components/RedirectModal";
export interface BlogProps {
  pageContext: {
    modules: [];
    slug: string;
    main: {
      title: string;
    };
    meta: {};
    siteGlobal: {
      metaInformation: {};
    };
    selectCountry: {
      title: string;
      flag: {
        asset: string;
        alt: string
      },
      link: string;
    };
  };
  path?: string;
  preview?: boolean;
}

const Blog = ({ path, pageContext, preview = false }: BlogProps) => {
  const { modules, slug, siteGlobal, selectCountry } = pageContext;
  const blogsData = pageContext?.content?.opener;

  const { addDiscount } = useDiscount();
  const { checkout } = useCart();
  const id = checkout?.id;
  
  useEffect(() => {
    applyDiscount(id, addDiscount, checkout?.lineItems?.length);
  }, [id]);

  const url = `/blogs/${pageContext?.slug}`;

  const meta = pageContext?.meta;

  return (
    <Container
      notificationBar={
        pageContext.notificationBar
          ? pageContext.notificationBar[0].enabled
          : false
      }
    >
      <SEO
        metaInfo={meta}
        pagePath={url}
        globalMeta={siteGlobal?.metaInformation}
      />
      <RedirectModal data={selectCountry} />
      {blogsData?.showOpener && <BlogHero blogsData={blogsData} />}
      <div>{RenderModules(modules, pageContext)}</div>
    </Container>
  );
};

export default Blog;
