import React, { useState, useEffect, useCallback } from "react";
import { Router } from "@reach/router";

import { useLoads } from "react-loads";

import Page from "src/templates/page";
import Product from "src/templates/product";
import Blog from "src/templates/blog";
import Collection from "src/templates/collection";

import sanityClient from "src/api/sanity";
import {
  pageQuery, productQuery, blogQuery,
  siteGlobal, cartQuery, mobileMenuQuery, menusQuery,
  notificationBarQuery, selectCountryQuery, collectionQuery, returnMoneyBackQuery
} from "src/api/queries";

const PreviewPage = ({ document }: { document: string }) => {
  const [doc, setDoc] = useState(null as any);

  const settingsQuery = `{
    "siteGlobal": ${siteGlobal}[0],
    "cart": ${cartQuery}[0],
    "mobileMenu": ${mobileMenuQuery}[0],
    "menusQuery": ${menusQuery}[0],
    "notificationBar": ${notificationBarQuery},
    "returnMoneyBack": ${returnMoneyBackQuery},
    "selectCountry": ${selectCountryQuery}
  }`;

  //  @ts-ignore
  const queryDraft = `*[_id == "${document}"][0]  {
    ...,
  }`;

  // @ts-ignore
  const queryPreviewPage = `*[_id == "${document}"][0]  {
    ${pageQuery}
  }`;

  // @ts-ignore
  const queryPreviewProduct = `*[_id == "${document}"][0]  {
    ${productQuery}
  }`;

  const queryPreviewBlog = `*[_id == "${document}"][0]  {
    ${blogQuery}
  }`;

  const queryPreviewCollection = `*[_id == "${document}"][0]  {
    ${collectionQuery}
  }`;



  const handlePreviewFetch = useCallback(
    async () => {
      try {
        const sharedSettings = await sanityClient.fetch(settingsQuery);
        const draftDoc = await sanityClient.fetch(queryDraft);
        console.log({ sharedSettings, draftDoc });

        if (draftDoc._type === "page") {
          const page = await sanityClient.fetch(queryPreviewPage);
          setDoc({ ...page, ...sharedSettings });
        } else if (draftDoc._type === "productsShopify") {
          const product = await sanityClient.fetch(queryPreviewProduct);
          setDoc({ ...product, ...sharedSettings });
        } else if (draftDoc._type === "blog") {
          const blog = await sanityClient.fetch(queryPreviewBlog);
          setDoc({ ...blog, ...sharedSettings });
        } else if (draftDoc._type === "collection") {
          const collection = await sanityClient.fetch(queryPreviewCollection);
          setDoc({ ...collection, ...sharedSettings });
        }
      } catch (error) {
        console.log({ error });
      }
    }, []
  );

  const { error, isResolved, isPending, isReloading, load } = useLoads(
    "handlePreviewFetch",
    handlePreviewFetch as any,
    {
      defer: true,
    }
  );

  useEffect(() => {
    load();
    handlePreviewFetch();
  }, []);

  const renderPreview = () => {
    if (doc) {
      switch (doc._type) {
        case "page":
          return <Page pageContext={doc} preview={true} />;
        case "productsShopify":
          return <Product pageContext={doc} preview={true} />;
        case "blog":
          return <Blog pageContext={doc} preview={true} />;
        case "collection":
          return <Collection pageContext={doc} preview={true} />;
        default:
          break;
      }
    }
  };
  return (
    <>
      {(isPending || isReloading) && (
        <div className="ac">
          <span>Loading</span>
        </div>
      )}
      {isResolved && !isPending && renderPreview()}
    </>
  );
};

const Previews = () => {
  return (
    <div>
      <Router>
        <PreviewPage path="/previews/:document" />
      </Router>
    </div>
  );
};

export default Previews;
