const siteRoute = process.env.GATSBY_URL;

export const PageSchema = (
    meta, 
    slug
) => {
    const schema = {
        "@context": "https://schema.org/",
        "@type": "Corporation",
        name: "Groove",
        alternateName: "Easy Sleeper",
        description:
            meta?.metaDescription,
        url: `${siteRoute}/${slug}`,
        logo: "https://cdn.sanity.io/images/69kas9zj/production/45e879474f553c23441f25e10a2336e246afc9b6-144x62.svg",
        contactPoint: {
            "@type": "ContactPoint",
            "telephone": "0808 258 3559",
            "contactType": "customer service",
            "areaServed": "GB",
            "availableLanguage": ["en",]
        },
        sameAs: [
            "https://www.facebook.com/EasySleeper.Co",
            "https://www.trustpilot.com/review/easysleeper.co.uk",
            "https://www.instagram.com/groove.pillows/",
            "https://www.linkedin.com/company/groovepillows/"
        ]
    };

    return schema;
};


