import styled, { css } from "styled-components";
import Image from "src/components/Common/ImageFluid";
import Wrap from "src/components/Wrap";
import { SectionHeading } from "src/components/Text";
import BlockContent from "src/components/BlockContent";

export const BlogsHero = styled.div`
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-size: cover;

`;


export const StyledWrap = styled(Wrap)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 8rem auto 0 auto;
  justify-content: center;


  @media ${({ theme }) => theme.device.phone} { 
    align-items: center;
  }

  .content {
    font-family: 'Effra-Regular';
    .bg-title {
      font-size: 52px;
      color: #fff;
      font-weight: 700;
      margin: 0 0 2rem 0;

      @media ${({ theme }) => theme.device.phone} { 
        font-size: 32px;
      }
    }

    .bg-subtitle {
      font-size: 22px;
      color: #fff;
      font-weight: 400;
      margin: 0 0 1rem 0;
      width: 65%;

      @media ${({ theme }) => theme.device.phone} { 
        font-size: 18px;
        width: 95%;
      }
    }

    .author-data {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      font-weight: 700;
      font-size: 22px;
      @media ${({ theme }) => theme.device.phone} { 
        font-size: 18px;
        justify-content: flex-start;
      }

    }
  }

  .blog-group-title {
    flex-direction: column;
    font-size: 2.75rem;
    line-height: 1.975;
    margin-bottom: 40px;
    width: 30%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 6.2rem 8.1rem 6.6rem;
  }

  .blog-groups-container {
    width: 100%;

    .main-div {
      width: 95%;
      background-color: #fff;
      border: unset;
      border-radius: unset;
      box-shadow: 0 3px 6px #00000029;
      display: flex;
      flex-direction: row;
      margin: 0 10px 60px 10px;
      padding: 23px;
      flex-direction: column;

      .bg-content {
        width: 100%;
      }
    }
  }
`;



export const StyledTextContainer = styled.div`
display: flex;
width: 37%;

`;

export const StyledBlogContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`;

export const StyledBlogItems  = styled.div`
display: flex;
width: 63%;

.single-blog-item {
    width: 25%;
}
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;

`;


