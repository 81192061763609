import React from "react"
import getImagePath from "src/utils/getImagePath";
import {
    StyledWrap, 
    BlogsHero
} from "./style";

const BlogHero = ({blogsData}) => {
    
  return (
      
    <BlogsHero style={{backgroundImage: `url(${getImagePath(blogsData?.image, true)})` }}>
        <StyledWrap>
            <div className="content">
                <div className="title-subtitle">
                    <h2 className="bg-title">{blogsData?.subtitle}</h2>
                    {blogsData?.blogContent &&
                    <h5 className="bg-subtitle">{blogsData?.blogContent}</h5>}
                </div>
                <div className="author-data">
                    <p className="author">{blogsData?.author}</p>
                </div>

            </div>
        </StyledWrap>
    </BlogsHero>
  )
}

export default BlogHero;