import React, { useEffect } from "react";

import { RenderModules } from "src/utils/renderModules";
import SEO from "src/components/SEO";
import { lazyLoadVideos } from "src/utils/lazyLoad";
import { useDiscount, useCart } from "src/context/siteContext";
import applyDiscount from "src/utils/applyDiscount";
import RedirectModal from "src/components/RedirectModal";
import { PageSchema } from "src/components/RichSnippet/page";
import { VideoObjectSchema } from "src/components/VideoObject/page";

export interface PageProps {
  pageContext: {
    modules: [];
    slug: string;
    main: {
      title: string;
    };
    content: {
      meta: {};
    };
    siteGlobal: {
      metaInformation: {};
    };
    selectCountry: {
      title: string;
      flag: {
        asset: string;
        alt: string
      },
      link: string;
    };
    reviews: [];
  };
  path?: string;
  preview?: boolean;
}

const Page = ({ path, pageContext, preview = false }: PageProps) => {
  const {
    modules,
    slug,
    siteGlobal,
    selectCountry={},
    meta,
  } = pageContext;


  const { addDiscount } = useDiscount();
  const { checkout } = useCart();
  const id = checkout?.id;
  const url = slug === "home" ? "" : `/${pageContext?.slug}`;

  useEffect(() => {
    lazyLoadVideos();
  }, []);

  useEffect(() => {
    applyDiscount(id, addDiscount, checkout?.lineItems?.length);
  }, [id]);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <script type="application/ld+json">
              ${JSON.stringify(
            PageSchema(
              pageContext?.meta, 
              slug,
            )
          )}
            </script>
            <script type="application/ld+json">
              ${JSON.stringify(
                VideoObjectSchema()
              )}
            </script>
          `,
        }}
      />
      <SEO
        pagePath={url}
        metaInfo={meta}
        globalMeta={siteGlobal?.metaInformation}
      />
      <RedirectModal data={selectCountry} />
      <div>{RenderModules(modules, pageContext)}</div>
    </div>
  );
};

export default Page;
