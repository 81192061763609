
export const VideoObjectSchema = () => {
    const schema = {
        "@context": "https://schema.org/",
        "@type": "VideoObject",
        "@id": "#video1",
        "name": "The Original Groove® Pillow - Recommended by 850+ Physiotherapists",
        "description": "Recommended by 850+ Physiotherapists. The Original Groove® Pillow is a memory foam pillow that is scientifically designed to improve sleep posture and alleviate stress on the neck and upper back. Its ergonomic 'dip' provides anatomical comfort and encourages proper biomechanical positioning, ensuring a restful night's sleep and leaving you feeling energized, pain-free, and ready to conquer the day.",
        "thumbnailUrl": "https://cdn.sanity.io/images/69kas9zj/production/d28df2ecc2ff221f3a473d84bfebc4b6bacf62d3-2000x1333.jpg",
        "duration": "PT22S",
        "contentUrl": "https://cdn.sanity.io/files/69kas9zj/production/71b8b27e9668ed55694a818ebd0652b657b6c088.mp4",
        "uploadDate": "2024-07-23"
    };

    return schema;
};


